import { useQuery } from "@vue/apollo-composable"
import { useMutation } from "@vue/apollo-composable"
import gql from "graphql-tag"
import { defineStore } from "pinia"
import { computed, ref } from "vue"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"

import {
  type Child,
  type ChildList,
  Table,
  type QueryRootChildrenArgs,
  type MutationRootCreateChildArgs,
  type MutationRootRemoveChildrenArgs,
  type MutationRootUpdateChildArgs,
  type QueryRootChildByIdArgs,
  type CreateChildAddressInput,
  type Booking,
  FunctionalRole,
  type CreateContractInput,
  type UpdateContractInput,
  type CreateContractInstitutionSectionInput,
  type UpdateContractInstitutionSectionInput,
  type CreateCustodianInput,
  type CreateCustodianChildInput,
  type UpdateCustodianChildInput,
  type UpdateCustodianInput,
  type Sibling,
  type MutationRootCreateSiblingAssignmentArgs,
  type MutationRootRemoveSiblingAssignmentsArgs,
  type CreateSiblingAssignmentInput,
} from "../graphql/types"

import { useCharacteristicStore } from "./characteristic"
import { type ChildGroupAssignment } from "./childGroup"
import { type CustodianCategoryAssignment } from "./custodianCategory"
import { useCustomerStore } from "./customer"
import { useDialogStore } from "./dialog"
import { useInstitutionStore } from "./institution"
import { useOrganizationStore } from "./organization"
import { useSessionStore } from "./session"

import BirthdayListQuery from "@/graphql/child/BirthdayList.gql"
import ChildByIdQuery from "@/graphql/child/ChildById.gql"
import CreateChildMutation from "@/graphql/child/CreateChild.gql"
import RemoveChildrenMutation from "@/graphql/child/RemoveChildren.gql"
import CreateSiblingMutation from "@/graphql/child/sibling/CreateSibling.gql"
import RemoveSiblingMutation from "@/graphql/child/sibling/RemoveSiblings.gql"
import SiblingAssignmentFragment from "@/graphql/child/sibling/SiblingAssignmentFragment.gql"
import UpdateChildMutation from "@/graphql/child/UpdateChild.gql"
import StateFragmentFull from "@/graphql/contract/StateFragmentFull.gql"
import { type AddressTypes } from "@/types"
import { useApi } from "@/utils/composables/apollo/useApi"
import { useDataTableCache } from "@/utils/composables/useDataTableCache"
import { useTablePresetConfiguration } from "@/utils/composables/useTablePresetConfiguration"
import { type StrictEntityId } from "@/utils/entity"
import ChildTableConfig from "@/views/children/children/ChildTableConfig"
import { apolloClient } from "@/vue-apollo"

export type CreateSiblingResult = { createSiblingAssignment: Sibling[] }
export type RemoveSiblingResult = { removeSiblingAssignments: number }

export type ChildListResult = { children: ChildList }
export type ChildByIdResult = { childById: Child }
export type ChildCreateResult = { createChild: Child }
export type ChildUpdateResult = { updateChild: Child }
export type ChildRemoveResult = { removeChildren: number }

export type MappedChild = Child & {
  childAddressAssignements?: (CreateChildAddressInput & AddressTypes)[]
  childCustodianAssginments?: ChildCustodianAssignment
  custodianCategoryAssginments?: CustodianCategoryAssignment
  createContractInput?: Omit<CreateContractInput, "childId">
  contractInstitutionSectionAssignments?: ContractInstitutionSectionAssignments
  childBookingAssignements?: Booking[]
  childCharacteristicsIds?: Map<StrictEntityId, StrictEntityId>
  characteristicIds: StrictEntityId[]
}

export type ContractInstitutionSectionAssignments = {
  create?: Omit<CreateContractInstitutionSectionInput, "contractId">[]
  update?: { id: StrictEntityId; input: UpdateContractInstitutionSectionInput }[]
  remove?: StrictEntityId[]
}

export type ChildCustodianAssignment = {
  create?: {
    custodian: CreateCustodianInput
    childCustodian: Omit<CreateCustodianChildInput, "childId" | "custodianId">
  }
  update?: {
    custodian?: { id: StrictEntityId; input: UpdateCustodianInput }
    childCustodian?: { id: StrictEntityId; input: UpdateCustodianChildInput }
  }
}

export type ChildDraft = Partial<
  Child & {
    characteristicIds: StrictEntityId[]
    childCharacteristicsIds?: Map<StrictEntityId, StrictEntityId>
    groupAssignments: ChildGroupAssignment[]
    childAddressAssignements: (CreateChildAddressInput & AddressTypes)[]
    childAddressUnassignements: StrictEntityId[]
    childCustodianAssginments: ChildCustodianAssignment
    custodianCategoryAssginments: CustodianCategoryAssignment
    childBookingAssignement: Booking
    childBookingAssignements: Booking[]
    groupAction: string
    createContractInput: Omit<CreateContractInput, "childId">
    updateContractInput: UpdateContractInput
    contractInstitutionSectionAssignments: ContractInstitutionSectionAssignments
  }
>

export const childResultMap = {
  getList: (result: ChildListResult) => result.children,
  getById: (result: ChildByIdResult) => result.childById,
  getCreated: (result: ChildCreateResult) => result.createChild,
  getUpdated: (result: ChildUpdateResult) => result.updateChild,
  getRemovedCount: (result: ChildRemoveResult) => result.removeChildren,
  getLinkedCount: undefined,
}

export enum StepKey {
  Contract = "contract",
  General = "general",
  Miscellaneous = "miscellaneous",
  Vaccination = "vaccination",
  FirstGuardian = "firstGuardian",
  SecondGuardian = "secondGuardian",
  AdditionalGuardian = "additionalGuardian",
  Groups = "groups",
  Reservation = "reservation",
  Fee = "fee",
  Print = "print",
}

export type Step = {
  routeName: string
  required?: boolean
  disabled?: (completionState: Record<StepKey, boolean>) => boolean
  prevStepKey?: StepKey
  nextStepKey?: StepKey
  altNextStep?: {
    key: StepKey
    label: string
  }
}

export const stepMap: Record<StepKey, Step> = {
  [StepKey.Contract]: {
    routeName: "ChildCreateFormContract",
    required: true,
    nextStepKey: StepKey.General,
  },
  [StepKey.General]: {
    routeName: "ChildCreateFormGeneral",
    required: true,
    prevStepKey: StepKey.Contract,
    nextStepKey: StepKey.Miscellaneous,
  },
  [StepKey.Miscellaneous]: {
    routeName: "ChildCreateFormMiscellaneous",
    disabled: (state) => !state.contract || !state.general,
    prevStepKey: StepKey.General,
    nextStepKey: StepKey.Vaccination,
  },
  [StepKey.Vaccination]: {
    routeName: "ChildCreateFormVaccination",
    disabled: (state) => !state.contract || !state.general,
    prevStepKey: StepKey.Miscellaneous,
    nextStepKey: StepKey.FirstGuardian,
  },
  [StepKey.FirstGuardian]: {
    routeName: "ChildCreateFormLocalGuardian0",
    disabled: (state) => !state.contract || !state.general,
    prevStepKey: StepKey.Vaccination,
    nextStepKey: StepKey.SecondGuardian,
  },
  [StepKey.SecondGuardian]: {
    routeName: "ChildCreateFormLocalGuardian1",
    disabled: (state) => !state.contract || !state.general,
    prevStepKey: StepKey.FirstGuardian,
    nextStepKey: StepKey.AdditionalGuardian,
  },
  [StepKey.AdditionalGuardian]: {
    routeName: "ChildCreateFormLocalGuardian2",
    disabled: (state) => !state.contract || !state.general,
    prevStepKey: StepKey.SecondGuardian,
    nextStepKey: StepKey.Groups,
  },
  [StepKey.Groups]: {
    routeName: "ChildCreateFormGroups",
    disabled: (state) => !state.contract || !state.general,
    prevStepKey: StepKey.AdditionalGuardian,
    nextStepKey: StepKey.Reservation,
  },
  [StepKey.Reservation]: {
    routeName: "ChildCreateFormReservation",
    disabled: (state) => !state.contract || !state.general,
    prevStepKey: StepKey.Groups,
    nextStepKey: StepKey.Fee,
  },
  [StepKey.Fee]: {
    routeName: "ChildCreateFormFee",
    disabled: (state) => !state.contract || !state.general,
    prevStepKey: StepKey.Reservation,
    nextStepKey: StepKey.Print,
  },
  [StepKey.Print]: {
    routeName: "ChildCreatePrintContract",
    disabled: (state) => !state.contract || !state.general,
    prevStepKey: StepKey.Fee,
  },
}

export const useChildStore = defineStore(Table.Children, () => {
  const sessionStore = useSessionStore()
  const characteristicStore = useCharacteristicStore()
  const customerStore = useCustomerStore()
  const organizationStore = useOrganizationStore()
  const institutionStore = useInstitutionStore()
  const dialogStore = useDialogStore()
  const { t: $t } = useI18n()
  const router = useRouter()

  const presetConf = useTablePresetConfiguration(Table.Children, ChildTableConfig.columnDefs, [
    "ChildrenPage",
  ])

  const listQueryVariables = computed(() => ({
    org: sessionStore.institutionFilter,
    ...sessionStore.accessLevelListPermissions,
    includeCharacteristics: sessionStore.hasRoles(["characteristic:list"]),
    includeCommune: sessionStore.hasRoles(["commune:list"]),
    includeGroup: sessionStore.hasRoles(["group:list"]),
  }))

  const viewQueryVariables = computed(() => ({
    ...sessionStore.accessLevelViewPermissions,
    includeCharacteristics: sessionStore.hasRoles(["characteristic:view"]),
    includeCommune: sessionStore.hasRoles(["commune:view"]),
    includeGroup: sessionStore.hasRoles(["group:view"]),
    includeVaccination: sessionStore.hasRoles([FunctionalRole.ChildAllowSensitiveData]),
    includeContracts: sessionStore.hasRoles(["contract:view"]),
    includeCustodians: sessionStore.hasRoles(["custodian:view"]),
  }))

  const listQueryOptions = computed(() => ({
    enabled:
      sessionStore.hasRoles(["child:list"]) &&
      !!sessionStore.institutionFilter.institution &&
      !!presetConf.initialColumnPresetsLoaded,
  }))

  const PresetBuiltQuery = computed(
    () => gql`
      query ChildList($org: ContextFilter) {
        children(org: $org) {
          page
          limit
          total
          items {
            id
            siblings {
              ...SiblingAssignmentFragment
            }
            ${presetConf.queryFields}
            contracts {
              id
              startOfMembership
              endOfMembership
              state {
                ...StateFragmentFull
              }
              registrationDate
              startOfContract
              remarks
              reasonOfLeave
            }
          }
        }
      },
      ${SiblingAssignmentFragment},
      ${StateFragmentFull}
    `
  )

  const api = useApi<
    Child,
    "children",
    ChildListResult,
    QueryRootChildrenArgs,
    ChildByIdResult,
    QueryRootChildByIdArgs,
    ChildCreateResult,
    MutationRootCreateChildArgs,
    ChildUpdateResult,
    MutationRootUpdateChildArgs,
    ChildRemoveResult,
    MutationRootRemoveChildrenArgs
  >({
    typename: "Child",
    operations: {
      list: PresetBuiltQuery,
      getById: ChildByIdQuery,
      create: CreateChildMutation,
      update: UpdateChildMutation,
      remove: RemoveChildrenMutation,
      link: undefined,
    },
    resultMap: childResultMap,
    mapRemovedIds: (variables) => variables.ids,
    listQueryVariables,
    listQueryOptions,
    mutationVariables: viewQueryVariables,
    getByIdAdditionalVariables: viewQueryVariables,
  })

  const toMapped = (child: Child): MappedChild => ({
    ...child,
    characteristicIds: (child.characteristics ?? []).reduce<StrictEntityId[]>(
      (prev, assignment) =>
        assignment.characteristic?.id ? [...prev, assignment.characteristic.id] : prev,
      []
    ),
    childCharacteristicsIds: new Map(
      child.characteristics?.map((assignment) => [assignment.characteristic?.id, assignment.id]) ??
        []
    ),
  })

  const list = computed<MappedChild[]>(() => (api.listResult?.children.items ?? []).map(toMapped))

  const { result: birthdayList, loading: birthdaysLoading } = useQuery<{
    birthdays: ChildList
  }>(
    BirthdayListQuery,
    computed(() => ({
      ...listQueryVariables.value,
      includeInstitution: sessionStore.hasRoles(["institution:list"]),
    })),
    listQueryOptions
  )

  customerStore.api.addRemoveReducer(
    api.getListFilterRemoveReducer(
      (ids) => (item) => !ids.find((cid) => cid === item.institution?.customer?.cid)
    )
  )
  organizationStore.api.addRemoveReducer(
    api.getListFilterRemoveReducer(
      (ids) => (item) => !ids.find((oid) => oid === item.organization?.oid)
    )
  )
  institutionStore.api.addRemoveReducer(
    api.getListFilterRemoveReducer(
      (ids) => (item) => !ids.find((iid) => iid === item.institution?.iid)
    )
  )
  characteristicStore.api.addRemoveReducer(() => (cache, result) => {
    if (result?.data?.removeCharacteristics)
      cache.evict({ fieldName: "childById", broadcast: false })
  })
  api.addRemoveReducer(api.getListFilterRemoveReducer((ids) => (item) => !ids.includes(item.id)))

  const dataTableCache = useDataTableCache(list)

  function discardForm(dirty = false) {
    ;(dirty
      ? dialogStore.confirmDiscard($t("kinder.kinder.view.labels.confirm_discard_edit"))
      : Promise.resolve()
    ).then(() => router.push({ name: "ChildrenPage" }))
  }

  const createMultipleSiblingLoading = ref(false)

  const siblingMutationsLoading = computed(
    () =>
      createMultipleSiblingLoading.value ||
      createSiblingLoading.value ||
      removeSiblingsLoading.value
  )

  const { mutate: createSibling, loading: createSiblingLoading } = useMutation<
    CreateSiblingResult,
    MutationRootCreateSiblingAssignmentArgs
  >(CreateSiblingMutation, {
    errorPolicy: "all",
    update: (cache, result, { variables }) => {
      const { input } = variables || {}
      const siblings = result.data?.createSiblingAssignment
      if (!siblings || !input) return
      const [firstSibling, secondSibling] = siblings
      const updateSiblingCache = (assignment: Sibling, siblingAssignment: Sibling) => {
        const siblingId = assignment.sibling?.id
        if (!siblingId) return
        cache.modify({
          id: cache.identify({ __typename: "Child", id: siblingId }),
          fields: {
            siblings(existingRefs = [], { toReference }) {
              try {
                return existingRefs && existingRefs.length
                  ? [...existingRefs, toReference(siblingAssignment)]
                  : [toReference(siblingAssignment)]
              } catch (error) {
                console.error("Cache update error:", error)
                return existingRefs
              }
            },
          },
        })
      }
      updateSiblingCache(firstSibling, secondSibling)
      updateSiblingCache(secondSibling, firstSibling)
    },
  })

  const { mutate: removeSiblings, loading: removeSiblingsLoading } = useMutation<
    RemoveSiblingResult,
    MutationRootRemoveSiblingAssignmentsArgs & { childId: StrictEntityId }
  >(RemoveSiblingMutation, {
    errorPolicy: "all",
    update: (cache, _result, options) => {
      const { ids, childId } = options.variables || {}
      cache.modify<Child>({
        id: cache.identify({
          __typename: "Child",
          id: childId,
        }),
        fields: {
          siblings(existingRefs = [], { readField }) {
            try {
              return existingRefs?.filter((ref) => {
                const id = readField("id", ref)
                if (typeof id !== "string") return false
                return !ids?.includes(id)
              })
            } catch (error) {
              console.error("Cache update error:", error)
              return existingRefs
            }
          },
        },
      })
      cache.updateQuery<ChildListResult>(
        {
          query: PresetBuiltQuery.value,
          variables: listQueryVariables.value,
          overwrite: true,
        },
        (data) => {
          if (!data) return
          return {
            ...data,
            children: {
              ...data.children,
              items: data.children.items.map((child) => {
                const siblings = [...(child.siblings ?? [])].filter(
                  (sibling) => sibling.sibling?.id !== childId
                )
                return { ...child, siblings }
              }),
            },
          }
        }
      )
    },
  })

  async function linkSiblings<T extends { id: StrictEntityId; siblings?: Sibling[] | null }>(
    children: T[]
  ) {
    const siblingPairs: CreateSiblingAssignmentInput[] = []
    for (let i = 0; i < children.length; i++) {
      for (let j = i + 1; j < children.length; j++) {
        if (
          !(children[j].siblings ?? []).map(({ sibling }) => sibling?.id).includes(children[i].id)
        )
          siblingPairs.push({
            firstChild: children[i].id,
            secondChild: children[j].id,
          })
      }
    }
    if (siblingPairs.length === 0) return true
    createMultipleSiblingLoading.value = true
    try {
      const results = await Promise.all(
        siblingPairs.map((input) =>
          createSibling({
            context: sessionStore.institutionFilter,
            input,
          })
        )
      )
      if (results.some((result) => !result)) return false
      return true
    } catch (error) {
      console.error(error)
      return false
    } finally {
      createMultipleSiblingLoading.value = false
    }
  }

  async function getChildById(id: string): Promise<Child | undefined> {
    try {
      const result = await apolloClient.query<ChildByIdResult>({
        query: ChildByIdQuery,
        variables: {
          id,
          ...viewQueryVariables.value,
        },
        fetchPolicy: "network-only",
      })
      if (result) return result.data?.childById
    } catch (err) {
      console.error(err)
    }
  }

  return {
    api,
    list,
    toMapped,
    dataTableCache,

    listQueryVariables,
    viewQueryVariables,

    birthdayList,
    birthdaysLoading,

    presetConf,

    discardForm,

    siblingMutationsLoading,
    linkSiblings,
    createSibling,
    removeSiblings,
    getChildById,
  }
})
