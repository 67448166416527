import { type Child } from "@/graphql/types"
import { i18n } from "@/i18n"
import { type MappedChild } from "@/store/child"
import { type TableConfig } from "@/types"
import { formatDate } from "@/utils/date"

const config: TableConfig<Child, MappedChild> = {
  columnDefs: [
    { key: "n", header: i18n.global.t("labels.id"), size: "small" },
    {
      key: "person.firstname",
      header: i18n.global.t("kinder.kinder.felder.vorname.label"),
      size: "medium",
    },
    {
      key: "person.lastname",
      header: i18n.global.t("kinder.kinder.felder.nachname.label"),
      size: "medium",
    },
    {
      key: "person.gender",
      format: (child) => child.person?.gender && i18n.global.t(`genders.${child.person?.gender}`),
      header: i18n.global.t("kinder.kinder.felder.geschlecht.label"),
      size: "medium",
    },
    {
      key: "address.street",
      header: i18n.global.t("kinder.kinder.felder.strasse.label"),
      size: "big",
    },
    {
      key: "address.city",
      header: i18n.global.t("kinder.kinder.felder.ort.label"),
      size: "big",
    },
    {
      key: "address.zipCode",
      header: i18n.global.t("kinder.kinder.felder.plz.label"),
      size: "small",
    },
    {
      key: "address.country",
      format: (child) =>
        child.address?.country && i18n.global.t(`countries_code.${child.address?.country}`),
      header: i18n.global.t("kinder.kinder.felder.land.label"),
      size: "medium",
      active: false,
    },
    {
      key: "birth.date",
      format: (child) => child.birth?.date && formatDate(child.birth?.date),
      header: i18n.global.t("kinder.kinder.felder.geburtsdatum.label"),
      size: "medium",
    },
    {
      key: "birth.date",
      format: (child) => child.birth?.date && formatDate(child.birth?.date),
      header: i18n.global.t("kinder.kinder.felder.geburtsdatum.label"),
      size: "medium",
    },
    {
      key: "contact.phone",
      header: i18n.global.t("kinder.kinder.felder.telefon.label"),
      size: "medium",
    },
  ],
}

export default config
