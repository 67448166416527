import { type RouteRecordRaw } from "vue-router"

import { StepKey } from "@/store/child"
import { type AddressTypes, type Permission } from "@/types"

function generateAddressTypes(...types: (keyof AddressTypes)[]): AddressTypes {
  const addressTypes: AddressTypes = {}

  types.forEach((key) => {
    addressTypes[key] = true
  })

  return addressTypes
}

const routes: RouteRecordRaw[] = [
  {
    path: "/kinder",
    name: "ChildrenPage",
    component: () => import("./children/ChildrenPage.vue"),
    meta: {
      requiredRoles: <Permission[]>["child:list", "child:unlock"],
    },
  },
  {
    name: "ChildCreate",
    path: "/kinder/erstellen",
    redirect: {
      name: "ChildCreateFormContract",
    },
    component: () => import("./children/views/ChildCreate.vue"),
    meta: {
      requiredRoles: <Permission[]>["child:create", "child:unlock"],
    },
    children: [
      {
        path: "vertrag",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "contract" },
        children: [
          {
            name: "ChildCreateFormContract",
            path: "",
            component: () => import("./children/forms/FormContract.vue"),
          },
        ],
      },
      {
        path: "allgemein",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "general" },
        children: [
          {
            name: "ChildCreateFormGeneral",
            path: "",
            component: () => import("./children/forms/FormGeneral.vue"),
            children: [
              {
                name: "ChildCreateFormGeneralCreateCommune",
                path: "gemeinde",
                props: { listRouteName: "ChildCreateFormGeneral" },
                component: () => import("../stammdaten/commune/CommuneDialog.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "verschiedenes",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "miscellaneous" },
        children: [
          {
            name: "ChildCreateFormMiscellaneous",
            path: "",
            component: () => import("./children/forms/FormMiscellaneous.vue"),
            children: [
              {
                name: "CreateChildCreateInstitutionSpecialServiceDialog",
                path: "fachdienst/erstellen",
                props: {
                  listRouteName: "ChildCreateFormMiscellaneous",
                  addressTypes: generateAddressTypes("specialService"),
                },
                meta: {
                  requiredRoles: <Permission[]>["address:create"],
                },
                component: () => import("./children/forms/dialogs/CreateAddressDialog.vue"),
                children: [
                  {
                    path: "kategorie",
                    props: {
                      listRouteName: "CreateChildCreateInstitutionSpecialServiceDialog",
                    },
                    component: () => import("../stammdaten/categories/CategoryDialog.vue"),
                    meta: {
                      requiredRoles: <Permission[]>["category:create"],
                    },
                  },
                ],
              },
              {
                name: "CreateChildCreateDoctorDialog",
                path: "hausarzt/erstellen",
                props: {
                  listRouteName: "ChildCreateFormMiscellaneous",
                  addressTypes: generateAddressTypes("doctor"),
                },
                meta: {
                  requiredRoles: <Permission[]>["address:create"],
                },
                component: () => import("./children/forms/dialogs/CreateAddressDialog.vue"),
                children: [
                  {
                    path: "kategorie",
                    props: {
                      listRouteName: "CreateChildCreateDoctorDialog",
                    },
                    component: () => import("../stammdaten/categories/CategoryDialog.vue"),
                    meta: {
                      requiredRoles: <Permission[]>["category:create"],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "impfstatus",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "vaccination" },
        children: [
          {
            name: "ChildCreateFormVaccination",
            path: "",
            component: () => import("./children/forms/FormVaccination.vue"),
          },
        ],
      },
      ...[StepKey.FirstGuardian, StepKey.SecondGuardian, StepKey.AdditionalGuardian].map(
        (step, index) => ({
          name: `Bezugsperson${index}`,
          path: `bezugsperson-${index}`,
          component: () => import("./children/views/ChildDraftForm.vue"),
          props: { step },
          children: [
            {
              name: `ChildCreateFormLocalGuardian${index}`,
              path: "",
              component: () => import("./children/forms/FormLocalGuardian.vue"),
              props: { index },
              children: [
                {
                  name: `CreateEmployerDialog${index}`,
                  path: "arbeitgeber/erstellen",
                  props: {
                    listRouteName: `ChildCreateFormLocalGuardian${index}`,
                  },
                  meta: {
                    requiredRoles: <Permission[]>["address:create"], //TODO
                  },
                  component: () => import("./children/forms/dialogs/CreateEmployerDialog.vue"),
                  children: [
                    {
                      path: "kategorie",
                      props: { listRouteName: `CreateEmployerDialog${index}` },
                      component: () => import("../stammdaten/categories/CategoryDialog.vue"),
                      meta: {
                        requiredRoles: <Permission[]>["category:create"],
                      },
                    },
                  ],
                },
                {
                  path: "kategorie",
                  props: { listRouteName: `ChildCreateFormLocalGuardian${index}` },
                  component: () => import("../stammdaten/address/CreateCategoryDialog.vue"),
                  meta: {
                    requiredRoles: <Permission[]>["category:create"],
                  },
                },
              ],
            },
          ],
        })
      ),
      {
        path: "gruppen",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "groups" },
        children: [
          {
            name: "ChildCreateFormGroups",
            path: "",
            component: () => import("./children/forms/FormGroups.vue"),
          },
          {
            path: "erstellen",
            name: "CreateChildGroupCreate",
            component: () => import("./children/forms/ManageGroup.vue"),
            meta: {
              requiredRoles: <Permission[]>["group:create", "group:unlock"],
            },
          },
          {
            path: ":childGroupId",
            name: "CreateChildGroupEdit",
            component: () => import("./children/forms/ManageGroup.vue"),
            meta: {
              requiredRoles: <Permission[]>["group:create", "group:unlock"],
            },
          },
        ],
      },
      {
        path: "buchung",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "reservation" },
        children: [
          {
            name: "ChildCreateFormReservation",
            path: "",
            component: () => import("./children/forms/FormReservation.vue"),
            children: [
              {
                path: "schule/erstellen",
                name: "ChildCreateReservationFormSchoolDialogCreate",
                component: () => import("../stammdaten/school/SchoolDialog.vue"),
                props: { listRouteName: "ChildCreateFormReservation" },
                meta: {
                  requiredRoles: <Permission[]>["school:create"],
                },
              },
            ],
          },
        ],
      },
      {
        path: "gebuehren",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "fee" },
        children: [
          {
            name: "ChildCreateFormFee",
            path: "",
            component: () => import("./children/forms/FormFee.vue"),
          },
        ],
      },
      {
        path: "vertrag",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "print" },
        children: [
          {
            name: "ChildCreatePrintContract",
            path: "",
            component: () => import("./children/forms/PrintContract.vue"),
          },
        ],
      },
      {
        name: "ChildCreateConclusion",
        path: "abschluss",
        component: () => import("./children/views/ChildCreateConclusion.vue"),
      },
    ],
  },
  {
    name: "ChildEdit",
    path: "/kinder/:id",
    redirect: (to) => ({ name: "ChildEditFormGeneral", params: { id: to.params.id } }),
    component: () => import("./children/views/ChildView.vue"),
    meta: {
      requiredRoles: <Permission[]>["child:view", "child:unlock"],
    },
    children: [
      {
        name: "ChildEditFormGeneral",
        path: "allgemein",
        component: () => import("./children/forms/FormGeneral.vue"),
        children: [
          {
            name: "ChildEditFormGeneralCreateCommune",
            path: "gemeinde",
            props: { listRouteName: "ChildEditFormGeneral" },
            component: () => import("../stammdaten/commune/CommuneDialog.vue"),
          },
        ],
      },
      {
        name: "ChildEditFormMiscellaneous",
        path: "verschiedenes",
        component: () => import("./children/forms/FormMiscellaneous.vue"),
        children: [
          {
            name: "ChildEditInstitutionSpecialServiceDialog",
            path: "hausarzt/erstellen",
            props: {
              listRouteName: "ChildEditFormMiscellaneous",
              addressTypes: generateAddressTypes("doctor"),
            },
            component: () => import("./children/forms/dialogs/CreateAddressDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["address:create"],
            },
            children: [
              {
                path: "kategorie",
                props: { listRouteName: "ChildEditInstitutionSpecialServiceDialog" },
                component: () => import("../stammdaten/categories/CategoryDialog.vue"),
                meta: {
                  requiredRoles: <Permission[]>["category:create"],
                },
              },
            ],
          },
          {
            name: "ChildEditDoctorDialog",
            path: "fachdienst/erstellen",
            props: {
              listRouteName: "ChildEditFormMiscellaneous",
              addressTypes: generateAddressTypes("specialService"),
            },
            component: () => import("./children/forms/dialogs/CreateAddressDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["address:create"],
            },
            children: [
              {
                path: "kategorie",
                props: { listRouteName: "ChildEditDoctorDialog" },
                component: () => import("../stammdaten/categories/CategoryDialog.vue"),
                meta: {
                  requiredRoles: <Permission[]>["category:create"],
                },
              },
            ],
          },
        ],
      },
      {
        name: "ChildEditFormVaccination",
        path: "impfstatus",
        component: () => import("./children/forms/FormVaccination.vue"),
      },
      {
        name: "ChildEditFormLocalGuardian",
        path: "bezugsperson",
        component: () => import("./children/forms/ManageCustodians.vue"),
        meta: {
          requiredRoles: <Permission[]>["custodian:list"],
        },
      },
      {
        name: "ChildEditFormSiblings",
        path: "geschwister",
        component: () => import("./children/forms/ManageSiblings.vue"),
        children: [
          {
            name: "NeueGeschwister",
            path: "erstellen",
            component: () => import("./children/forms/dialogs/NewSiblingDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["child:update"],
            },
          },
        ],
      },
      {
        path: "bezugsperson/erstellen",
        name: "UpdateChildCreateCustodian",
        component: () => import("./children/forms/FormLocalGuardian.vue"),
        meta: {
          requiredRoles: <Permission[]>["custodian:create"],
        },
        children: [
          {
            name: "UpdateChildCreateCustodianCreateEmployer",
            path: "arbeitgeber/erstellen",
            props: {
              listRouteName: `UpdateChildCreateCustodian`,
            },
            meta: {
              requiredRoles: <Permission[]>["custodian:create"], //TODO
            },
            component: () => import("./children/forms/dialogs/CreateEmployerDialog.vue"),
            children: [
              {
                path: "kategorie",
                props: { listRouteName: `CUpdateChildCreateCustodianCreateEmployer` },
                component: () => import("../stammdaten/categories/CategoryDialog.vue"),
                meta: {
                  requiredRoles: <Permission[]>["category:create"],
                },
              },
            ],
          },
          {
            path: "kategorie",
            props: { listRouteName: `UpdateChildCreateCustodian` },
            component: () => import("../stammdaten/categories/CategoryDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["category:create"],
            },
          },
        ],
      },
      {
        name: "ChildEditFormLocalGuardianEdit",
        path: "bezugsperson/:bid",
        component: () => import("./children/forms/FormLocalGuardian.vue"),
        children: [
          {
            name: "ChildEditFormLocalGuardianEditCreateEmployer",
            path: "arbeitgeber/erstellen",
            props: {
              listRouteName: `ChildEditFormLocalGuardianEdit`,
            },
            meta: {
              requiredRoles: <Permission[]>["custodian:create"], //TODO
            },
            component: () => import("./children/forms/dialogs/CreateEmployerDialog.vue"),
            children: [
              {
                path: "kategorie",
                props: { listRouteName: `ChildEditFormLocalGuardianEditCreateEmployer` },
                component: () => import("../stammdaten/categories/CategoryDialog.vue"),
                meta: {
                  requiredRoles: <Permission[]>["category:create"],
                },
              },
            ],
          },
          {
            path: "kategorie",
            props: { listRouteName: `ChildEditFormLocalGuardianEdit` },
            component: () => import("../stammdaten/categories/CategoryDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["category:create"],
            },
          },
        ],
      },
      {
        name: "ChildEditFormContract",
        path: "vertrag/:contractId",
        component: () => import("./children/forms/FormContract.vue"),
      },
      {
        name: "ChildEditFormGroups",
        path: "gruppen",
        component: () => import("./children/forms/FormGroups.vue"),
      },
      {
        path: "gruppen/erstellen",
        name: "ChildGroupCreate",
        component: () => import("./children/forms/ManageGroup.vue"),
        meta: {
          requiredRoles: <Permission[]>["group:create", "group:unlock"],
        },
      },
      {
        path: "gruppen/:childGroupId",
        name: "ChildGroupEdit",
        component: () => import("./children/forms/ManageGroup.vue"),
        meta: {
          requiredRoles: <Permission[]>["group:create", "group:unlock"],
        },
      },
      {
        name: "ChildEditFormReservation",
        path: "buchung",
        component: () => import("./children/forms/FormReservation.vue"),
        children: [
          {
            path: "schule/erstellen",
            name: "ChildEditReservationFormSchoolDialogCreate",
            component: () => import("../stammdaten/school/SchoolDialog.vue"),
            props: { listRouteName: "ChildEditFormReservation" },
            meta: {
              requiredRoles: <Permission[]>["school:create"],
            },
          },
        ],
      },
      {
        name: "ChildReservationCalendar",
        path: "buchungskalendar",
        component: () => import("./children/views/ReservationCalendar.vue"),
      },
      {
        name: "ChildEditFormFee",
        path: "gebuehren",
        component: () => import("./children/forms/FormFee.vue"),
      },
      {
        name: "ChildEditPrintContract",
        path: "vertrag-drucken",
        component: () => import("./children/forms/PrintContract.vue"),
      },
      {
        name: "ComingSoon",
        path: "coming-soon/:pageName",
        component: () => import("./children/views/ComingSoon.vue"),
      },
    ],
  },
  {
    path: "/kinder-ansicht",
    name: "ChildrenListView",
    component: () => import("./children/views/ChildrenListView.vue"),
  },
  {
    path: "/anwesenheit",
    name: "PresencePage",
    component: () => import("./children/views/PresencePage.vue"),
    meta: {
      requiredRoles: <Permission[]>["child:list", "child:unlock"],
    },
  },
  {
    path: "/gruppen",
    name: "GroupPage",
    component: () => import("./groups/GroupPage.vue"),
    meta: {
      requiredRoles: <Permission[]>["group:list", "group:unlock"],
    },
  },
  {
    path: "/gruppen/erstellen",
    name: "GroupViewCreate",
    component: () => import("./groups/GroupView.vue"),
    meta: {
      requiredRoles: <Permission[]>["group:create", "group:unlock"],
    },
    children: [
      {
        path: "",
        name: "GroupFormCreate",
        component: () => import("./groups/GroupForm.vue"),
        children: [
          {
            path: "management/erstellen",
            name: "GroupCreateManagementFormCreate",
            props: { listRouteName: "GroupFormCreate" },
            component: () => import("./groups/ManagementDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["employee:update"],
            },
          },
          {
            path: "management/:managementId",
            name: "GroupCreateManagementFormEdit",
            props: { listRouteName: "GroupFormCreate" },
            component: () => import("./groups/ManagementDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["employee:update"],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/gruppen/:id",
    name: "GroupViewEdit",
    redirect: (to) => ({ name: "GroupFormEdit", params: { id: to.params.id } }),
    component: () => import("./groups/GroupView.vue"),
    meta: {
      requiredRoles: <Permission[]>["group:view", "group:unlock"],
    },
    children: [
      {
        path: "",
        name: "GroupFormEdit",
        component: () => import("./groups/GroupForm.vue"),
        children: [
          {
            path: "management/erstellen",
            name: "GroupEditManagementFormCreate",
            props: { listRouteName: "GroupFormEdit" },
            component: () => import("./groups/ManagementDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["employee:update"],
            },
          },
          {
            path: "management/:managementId",
            name: "GroupEditManagementFormEdit",
            props: { listRouteName: "GroupFormEdit" },
            component: () => import("./groups/ManagementDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["employee:update"],
            },
          },
        ],
      },
    ],
  },
]

export default routes
